import React, { useState } from 'react';
import Input from './Input';
import Data from './Data';
import strings from '../data/strings.json';
import '../styles/Form.css';

const Form = ({ onSearch }) => {
  const [input, setInput] = useState('');
  const [data, setData] = useState(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://api.motorcyclepartshub.com/RegistrationCheck/?reg=${input}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      onSearch(data);

      setData(data);
    } catch (error) {
      console.log(data);
      console.error('Error fetching motorbike data:', error);
    }

    setInput('');
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          input={input}
          handleInputChange={handleInputChange}
        />
        <button type="submit">{strings.search_button}</button>
      </form>
  
      {(data !== null) && (
        data ? <Data data={data} /> : <p>Incorrect Registration entered</p>
      )}
    </>
  );
};

export default Form;
