import React, { useState } from 'react';
import '../styles/Table.css';

const camelCaseToWords = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, l => l.toUpperCase())
            .replace(' Id', ' ID').replace('Id ', 'ID ');
};

const addQuestionMarkIfNeeded = (str) => {
  if (str.startsWith('Is ')) {
    return str + '?';
  }
  return str;
};

const CollapsibleRow = ({ label, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <tr onClick={() => setIsCollapsed(!isCollapsed)}>
        <th colSpan="2">{label}</th>
      </tr>
      {!isCollapsed && children}
    </>
  );
};

const Table = ({ data }) => {
  const separateProperties = (obj) => {
    const nonObjectProps = {};
    const objectProps = {};

    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        objectProps[key] = obj[key];
      } else {
        nonObjectProps[key] = obj[key];
      }
    }

    return { nonObjectProps, objectProps };
  };

  const renderTableRows = (obj, depth = 0, parentKey = null) => {
    const rows = [];

    const { nonObjectProps, objectProps } = separateProperties(obj);

    const sortedNonObjectKeys = Object.keys(nonObjectProps).sort();
    for (const key of sortedNonObjectKeys) {
      const value = nonObjectProps[key];
      if (value !== null && value !== '') {
        const formattedKey = camelCaseToWords(key);
        const formattedKeyWithQuestionMark = addQuestionMarkIfNeeded(formattedKey);
        rows.push(
          <tr key={key}>
            <td>{formattedKeyWithQuestionMark}</td>
            <td>{value.toString()}</td>
          </tr>
        );
      }
    }

    const sortedObjectKeys = Object.keys(objectProps).sort();
    for (const key of sortedObjectKeys) {
      const value = objectProps[key];
      if (value !== null && value !== '') {
        const formattedKey = camelCaseToWords(key);
        const formattedKeyWithQuestionMark = addQuestionMarkIfNeeded(formattedKey);

        rows.push(
          <CollapsibleRow key={key} label={parentKey || formattedKeyWithQuestionMark}>
            {renderTableRows(value, depth + 1, parentKey || formattedKeyWithQuestionMark)}
          </CollapsibleRow>
        );
      }
    }

    return rows;
  };

  const tableRows = renderTableRows(data);

  return (
    <table>
      <tbody>
        {tableRows.length > 0 ? (
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        ) : null}
        {tableRows}
      </tbody>
    </table>
  );
};

export default Table;
