import React from 'react';
import strings from '../data/strings.json';
import '../styles/Input.css';

const Input = ({ input, handleInputChange }) => {
  return (
    <>
      <label htmlFor="input">{strings.input_label}</label>
      <input
          type="text"
          id="input"
          value={input}
          onChange={handleInputChange}
          required
        />
    </>
  );
};

export default Input;