import React, { useState } from 'react';
import Form from './components/Form';
import './App.css';

function App() {
  const [, setMotorbikeData] = useState(null);

  const handleSearch = (data) => {
    setMotorbikeData(data);
  };

  return (
    <>
      <Form onSearch={handleSearch} />
    </>
  );
}

export default App;