import React from 'react';
import Table from './Table';

const Data = ({ data }) => {
  return (
    <>
      <Table data={data} />
    </>
  );
};

export default Data;